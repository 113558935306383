import React from 'react';
import Navigation from './Navigation/Navigation';
import HomeHero from './Home/Hero';
import AboutUs from './Home/AboutUs';
import Target from './Home/Targets';
import Discord from './Home/Discord';
import { ThemeProvider, Container, Row } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';

const app = () => {
    
    return (
        <ThemeProvider breakpoints={['xxl', 'xl', 'md', 'sm']}>
            <Container fluid={true} sm={12} style={{overflow: "hidden"}}>
                <Row>
                    <Navigation />
                </Row>
                <Row>
                    <HomeHero />
                </Row>
                <Row style={{margin: 0}}>
                    <AboutUs />
                </Row>
                <Row style={{margin: 0}}>
                    <Target />
                </Row>
                <Row style={{margin: 0}}>
                    <Discord />
                </Row>
            </Container>
            <CookieConsent
                location="bottom"
                buttonText="Akzeptieren"
                declineButtonText="Ablehnen"
                cookieName="meinCookie"
                hideOnAccept="true"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                enableDeclineButton
                onDecline={() => {
                    
                }}
            >
                Wir benutzen Cookies um die Benutzererfahrung zu verbessern.{" "}
            </CookieConsent>
        </ThemeProvider>
    )
}

export default app;