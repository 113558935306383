import React from 'react';
import './Hero.css';
import ReactPlayer from 'react-player';
import heroVideo from '../../assets/hero-video.mp4';
import { ButtonBlue } from '../UX/ux';

const homeHero = () => {

    return (
        <div className="homeHeroContainer">
            <ReactPlayer className={'homeHeroVideo'} url={heroVideo} playing loop muted width="100vw" height="100vh" />
            <div className={'homeHeroText'}>
                <h1>carl benz gaming ag</h1>
                <h2>carl benz schule koblenz</h2>
                <ButtonBlue text={"Erfahre mehr"} href={"#aboutus"}></ButtonBlue>
            </div>
        </div>
    )
}

export default homeHero;