import React from 'react';
import './Targets.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faShieldAlt, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';

const Target = () => {
    return (
        <div className="targetContainer">
            <div id={"targets"} className={"box"}>
                <h2>Was sind unsere Ziele?</h2>
                <div className={"cardBox"}>
                    <div className={"card"}>
                        <FontAwesomeIcon className={"icon"} icon={faPeopleArrows} />
                        <h3>Gemeinschaft</h3>
                        <p>wir möchten die Schüler zusammenbringen und einen Ort schaffen, an dem man Gleichgesinnte treffen kann</p>
                    </div>
                    <div className={"card"}>
                        <FontAwesomeIcon className={"icon"} icon={faShieldAlt} />
                        <h3>Turniere</h3>
                        <p>in Zukunft möchten wir verschiedene Turniere anbieten, zu verschiedenen Spielen</p>
                    </div>
                    <div className={"card"}>
                        <FontAwesomeIcon className={"icon"} icon={faGamepad} />
                        <h3>Gaming</h3>
                        <p>wir haben Spaß am zocken und suchen immer neue Mitspieler, welche unser Team bereichern!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Target;