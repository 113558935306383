import React from "react";
import './Discord.css';

const Discord = () => {
    return (
        <div id={"discord"} className={"discordInformationContainer"}>
            <div className="text">
                <h2>Wie kann ich dabei sein?</h2>
                <p>
                    Du bist Schüler der Carl Benz Schule Koblenz?<br/>
                    Dann tritt einfach dem Discord bei!
                </p>
            </div>
            <iframe id={"DiscordWidget"} title={"DiscordWidget"} src="https://discord.com/widget?id=905472324847960104&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </div>
    )
}

export default Discord;