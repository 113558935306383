import React from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import './Navigation.css';
import logo from '../../assets/logo.png';
import instagram from '../../assets/instagram_icon.png';
import twitch from '../../assets/twitch-logo.png';
import discord from '../../assets/discordLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';

const navigation = () => {
    return (
        <Navbar fixed="top" bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand href="#home">
                    <img
                        src={logo}
                        width="82"
                        height="82"
                        className="d-inline-block align-top"
                        alt="Carl-Benz-Gaming"
                    />
                </Navbar.Brand>
                <Navbar.Brand href="https://www.instagram.com/carlbenz.gaming/" target="_blank">
                    <img
                        src={instagram}
                        width="32"
                        height="32"
                        className="d-inline-block align-top"
                        alt="Instagram"
                    />
                </Navbar.Brand>
                <Navbar.Brand href="https://www.twitch.tv/carlbenzgaming" target="_blank">
                    <img
                        src={twitch}
                        width="32"
                        height="32"
                        className="d-inline-block align-top"
                        alt="Twitch"
                    />
                </Navbar.Brand>
                <Navbar.Brand href="#discord">
                    <img
                        src={discord}
                        width="32"
                        height="32"
                        className="d-inline-block align-top"
                        alt="Discord"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="/">Startseite</Nav.Link>
                        <NavDropdown title="Über uns" id="navbarScrollingDropdown">
                            <NavDropdown.Item href="#history">Historie</NavDropdown.Item>
                            <NavDropdown.Item href="#teams">Unsere Teams</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="mailto:kontakt@carl-benz-gaming.de">Kontakt</Nav.Link>
                        <Nav.Link className="nvBig" href="#link" style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon style={{marginRight: "6px"}} icon={faCalendarDay} />
                            <span style={{paddingTop: "5px"}}>Events</span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default navigation;