import React from 'react';
import './AboutUs.css';
import logo from '../../assets/logo.png';
import bg from '../../assets/homeAboutUsBG.png';

const AboutUs = () => {
    return (
        <div id={"aboutus"} className={"aboutUsContainer"}>
            <div className={"textBox"}>
                <div className={"text"}>
                    <img src={logo} width={"180px"} alt={"CBG Logo"}></img>
                    <h1>Wer sind wir?</h1>
                    <p>Wir sind die Gaming AG der Carl Benz Schule Koblenz</p>
                </div>
            </div>
            <img src={bg} alt={"background"}></img>
        </div>
    )
}

export default AboutUs;